import { Breakpoint } from '@mui/material';

export const spacing = [
  8,
  12,
  16,
  24,
  32,
  40,
  48,
  56,
  64,
  80,
  104,
  128,
  180,
];

export const mainColumnGutters: { [key in Breakpoint]?: string | number } = {
  xs: 3,
  sm: 7,
  md: 9,
  lg: '28px',
};

export const computedMainColumnGutters: { [key in Breakpoint]?: string } =
  Object.fromEntries(
    Object.entries(mainColumnGutters).map(
      ([breakpoint, gutter]) => [
        breakpoint,
        typeof gutter === 'number' ?
          `${spacing[gutter] || gutter}px` :
          typeof gutter === 'string' ?
            gutter :
            '0px',
      ]
    )
  );

export default spacing;
