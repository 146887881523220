import { PaletteOptions } from '@mui/material/styles';

export default {
  greyscale: {
    white: '#FFFFFF',
    cultured: '#F6F9FB',
    alice: '#E4EDF3',
    beau: '#D3E1EB',
    slate: '#9BB7C9',
    steel: '#507A94',
    indigo: '#04456A',
    persian: '#022539',
    main: '#541BD5',
    light: '#7648DD',
  },
  fileOrganization: {
    annotate: '#9747FF',
    connector: '#838383',
  },
  info: {
    main: '#3B77EA',
  },
  warning: {
    main: '#ECC025',
  },
  error: {
    main: '#EA503B',
  },
  success: {
    main: '#4DB263',
  },
  primary: {
    light: '#7648DD',
    main: '#541BD5',
    dark: '#3A1295',
  },
  secondary: {
    light: '#CFEF58',
    main: '#B6D348',
    dark: '#9CB833',
  },
  text: {
    primary: '#04456A',
  },
} as PaletteOptions;
