import { Components, Theme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';

export default {
  MuiCssBaseline: {
    styleOverrides: {
      h1: {
        ...typography.h1,
        color: palette?.greyscale.persian,
      },
      h2: {
        ...typography.h2,
        color: palette?.greyscale.persian,
      },
      h3: {
        ...typography.h3,
        color: palette?.greyscale.persian,
      },
      h4: {
        ...typography.h4,
        color: palette?.greyscale.white,
      },
      h5: {
        ...typography.h5,
      },
      h6: {
        ...typography.h6,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        fontWeight: typography.fontWeightSemiBold,
        textDecoration: 'none',
        cursor: 'pointer',
        color: palette?.greyscale.steel,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        boxShadow: 'none',
        transition: 'none',
        backgroundColor: palette?.primary,
        borderRadius: '10px',
        color: palette?.greyscale.white,
        border: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        padding: '16px 24px',
      },
      outlinedPrimary: {
        border: '2px solid',
        '&:hover': {
          border: '2px solid',
          borderColor: palette?.greyscale?.light,
          backgroundColor: palette?.greyscale?.light,
          color: palette?.greyscale.white,
        },
        backgroundColor: palette?.greyscale.white,
        borderRadius: '10px',
        color: palette?.primary,
        padding: '16px 24px',
      },
      sizeSmall: {
        fontSize: typography?.body2?.fontSize,
        padding: '13px 15px',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      label: {
        svg: {
          marginLeft: '0.5rem',
          marginBottom: -1,
        },
      },
      labelSmall: {
        fontSize: typography.body3.fontSize,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderColor: palette.greyscale.slate,
        },
        '& .MuiOutlinedInput-root:hover': {
          borderColor: palette.greyscale.steel,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: '16px',
        color: `${palette.greyscale.persian} !important`,
        fontSize: `${typography.body1?.fontSize} !important`,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: '12px !important',
        '&:hover': {
          borderColor: 'red !important',
        },
      },
      input: {
        color: palette.greyscale.persian,
        fontSize: typography.body1?.fontSize,
        '&::placeholder': {
          color: palette.greyscale.steel,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontWeight: typography.fontWeightMedium,
        margin: '8px 0px',
        fontSize: `${typography.body2?.fontSize} !important`,
        color: palette.greyscale.steel,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: palette.greyscale.slate,
      },
    },
  },
} as Components<Omit<Theme, 'components'>>;
