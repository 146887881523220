import { BreakpointsOptions } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

export const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1280,
  },
} as BreakpointsOptions;

export const themeBreakpoints =
  createBreakpoints(breakpoints);

export default breakpoints;
