import { TypographyOptions } from '@mui/material/styles/createTypography';
import { themeBreakpoints } from './breakpoints';

const HEADING_FONT_FAMILY = 'Plus Jakarta Sans, sans-serif';

// const HEADING_FONT_WEIGHT_REGULAR = 400;
const HEADING_FONT_WEIGHT_SEMIBOLD = 600;
// const HEADING_FONT_WEIGHT_BOLD = 700;

const HEADING_FONT_SIZE_H1 = { desktop: 80, mobile: 40 };
const HEADING_FONT_SIZE_H2 = { desktop: 48, mobile: 32 };
const HEADING_FONT_SIZE_H3 = { desktop: 40, mobile: 28 };
const HEADING_FONT_SIZE_H4 = { desktop: 32, mobile: 24 };
const HEADING_FONT_SIZE_H5 = { desktop: 24, mobile: 20 };
const HEADING_FONT_SIZE_H6 = { desktop: 24, mobile: 20 };

const HEADING_LINE_HEIGHT_H1 = { desktop: 96, mobile: 48 };
const HEADING_LINE_HEIGHT_H2 = { desktop: 56, mobile: 40 };
const HEADING_LINE_HEIGHT_H3 = { desktop: 48, mobile: 36 };
const HEADING_LINE_HEIGHT_H4 = { desktop: 40, mobile: 32 };
const HEADING_LINE_HEIGHT_H5 = { desktop: 32, mobile: 28 };
const HEADING_LINE_HEIGHT_H6 = { desktop: 32, mobile: 28 };

const BODY_FONT_FAMILY = 'Inter, sans-serif';

const BODY_FONT_WEIGHT_REGULAR = 400;
const BODY_FONT_WEIGHT_MEDIUM = 500;
const BODY_FONT_WEIGHT_SEMIBOLD = 600;
const BODY_FONT_WEIGHT_BOLD = 700;

const BODY_FONT_SIZE_BASE = { desktop: 20, mobile: 16 };
const BODY_FONT_SIZE_SMALL = { desktop: 16, mobile: 14 };
const BODY_FONT_SIZE_SMALLER = { desktop: 14, mobile: 12 };

const BODY_LINE_HEIGHT_BASE = { desktop: 28, mobile: 24 };
const BODY_LINE_HEIGHT_SMALL = { desktop: 24, mobile: 22 };
const BODY_LINE_HEIGHT_SMALLER = { desktop: 22, mobile: 20 };

const HTML_FONT_SIZE = 16; // Browser default (better not to change)
const BODY_FONT_SIZE = 14; // Browser default (better not to change)

const pxToRem = (px: number) => `${(px / HTML_FONT_SIZE) * (BODY_FONT_SIZE / 14)}rem`;
const lineHeightPxToUnitless =
  (lineHeightPx: number, fontSizePx: number) => lineHeightPx / fontSizePx;

export default {
  htmlFontSize: HTML_FONT_SIZE,
  fontSize: BODY_FONT_SIZE,

  fontFamily: BODY_FONT_FAMILY,
  fontWeightLight: undefined,
  fontWeightRegular: BODY_FONT_WEIGHT_REGULAR,
  fontWeightMedium: BODY_FONT_WEIGHT_MEDIUM,
  fontWeightSemiBold: BODY_FONT_WEIGHT_SEMIBOLD,
  fontWeightBold: BODY_FONT_WEIGHT_BOLD,

  h1: {
    fontFamily: HEADING_FONT_FAMILY,
    fontWeight: HEADING_FONT_WEIGHT_SEMIBOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H1.mobile),
    lineHeight: lineHeightPxToUnitless(
      HEADING_LINE_HEIGHT_H1.mobile, HEADING_FONT_SIZE_H1.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H1.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H1.desktop, HEADING_FONT_SIZE_H1.desktop
      ),
    },
  },
  h2: {
    fontFamily: HEADING_FONT_FAMILY,
    fontWeight: HEADING_FONT_WEIGHT_SEMIBOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H2.mobile),
    lineHeight: lineHeightPxToUnitless(
      HEADING_LINE_HEIGHT_H2.mobile, HEADING_FONT_SIZE_H2.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H2.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H2.desktop, HEADING_FONT_SIZE_H2.desktop
      ),
    },
  },
  h3: {
    fontFamily: HEADING_FONT_FAMILY,
    fontWeight: HEADING_FONT_WEIGHT_SEMIBOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H3.mobile),
    lineHeight: lineHeightPxToUnitless(
      HEADING_LINE_HEIGHT_H3.mobile, HEADING_FONT_SIZE_H3.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H3.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H3.desktop, HEADING_FONT_SIZE_H3.desktop
      ),
    },
  },
  h4: {
    fontFamily: HEADING_FONT_FAMILY,
    fontWeight: HEADING_FONT_WEIGHT_SEMIBOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H4.mobile),
    lineHeight: lineHeightPxToUnitless(
      HEADING_LINE_HEIGHT_H4.mobile, HEADING_FONT_SIZE_H4.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H4.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H4.desktop, HEADING_FONT_SIZE_H4.desktop
      ),
    },
  },
  h5: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_SEMIBOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H5.mobile),
    lineHeight: lineHeightPxToUnitless(
      HEADING_LINE_HEIGHT_H5.mobile, HEADING_FONT_SIZE_H5.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H5.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H5.desktop, HEADING_FONT_SIZE_H5.desktop
      ),
    },
  },
  h6: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_SEMIBOLD,
    fontSize: pxToRem(HEADING_FONT_SIZE_H6.mobile),
    lineHeight: lineHeightPxToUnitless(
      HEADING_LINE_HEIGHT_H6.mobile, HEADING_FONT_SIZE_H6.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(HEADING_FONT_SIZE_H6.desktop),
      lineHeight: lineHeightPxToUnitless(
        HEADING_LINE_HEIGHT_H6.desktop, HEADING_FONT_SIZE_H6.desktop
      ),
    },
  },

  body1: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(BODY_FONT_SIZE_BASE.mobile),
    lineHeight: lineHeightPxToUnitless(
      BODY_LINE_HEIGHT_BASE.mobile, BODY_FONT_SIZE_BASE.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(BODY_FONT_SIZE_BASE.desktop),
      lineHeight: lineHeightPxToUnitless(
        BODY_LINE_HEIGHT_BASE.desktop, BODY_FONT_SIZE_BASE.desktop
      ),
    },
  },
  body2: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(BODY_FONT_SIZE_SMALL.mobile),
    lineHeight: lineHeightPxToUnitless(
      BODY_LINE_HEIGHT_SMALL.mobile, BODY_FONT_SIZE_SMALL.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(BODY_FONT_SIZE_SMALL.desktop),
      lineHeight: lineHeightPxToUnitless(
        BODY_LINE_HEIGHT_SMALL.desktop, BODY_FONT_SIZE_SMALL.desktop
      ),
    },
  },
  body3: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
    fontSize: pxToRem(BODY_FONT_SIZE_SMALLER.mobile),
    lineHeight: lineHeightPxToUnitless(
      BODY_LINE_HEIGHT_SMALLER.mobile, BODY_FONT_SIZE_SMALLER.mobile
    ),
    letterSpacing: 0,
    [themeBreakpoints.up('md')]: {
      fontSize: pxToRem(BODY_FONT_SIZE_SMALLER.desktop),
      lineHeight: lineHeightPxToUnitless(
        BODY_LINE_HEIGHT_SMALLER.desktop, BODY_FONT_SIZE_SMALLER.desktop
      ),
    },
  },

  button: {
    fontFamily: BODY_FONT_FAMILY,
    fontWeight: BODY_FONT_WEIGHT_SEMIBOLD,
    fontSize: pxToRem(BODY_FONT_SIZE_SMALL.desktop),
    lineHeight: lineHeightPxToUnitless(
      BODY_LINE_HEIGHT_SMALL.desktop, BODY_FONT_SIZE_SMALL.desktop
    ),
    letterSpacing: 0,
    textTransform: 'none',
  },
} as TypographyOptions;
